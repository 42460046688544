<div class="dialogSize rounded">
    <p mat-dialog-title translate style="color:#0000ff" class="title h2" translate>{{data.title}}</p>
    <ul mat-dialog-content translate style="color: black;" class="text-justify py-3">
        <ng-container *ngFor="let item of data.content">
            <li translate>{{item}}</li>
            <br>
        </ng-container>
    </ul>
    <div class="justify-content-center" mat-dialog-actions>
      <button
      style="
      background: white; 
      border: 2px solid #0000FF !important; 
      font-weight: bold; 
      padding: 8px 50px;"
      translate 
      mat-button mat-dialog-close>Close</button>
    </div>
</div>