<div class="p-5 text-center rounded" 
    style="max-width: 85vh;">
    <h1 mat-dialog-title translate style="color:#0000ff" class="title">{{ data.title }}</h1>
    <p mat-dialog-content translate style="color: black;" class="text-justify" [innerHTML]="data.content"></p>
    <div class="justify-content-center" mat-dialog-actions style="gap: 10px;">
      <button style="
      background: #0000ff;
      border: none;
      font-weight: bold;
      padding: 8px 29px;
      color: white !important;
      margin-top: 1rem;"
      (click)="onButtonClick()"
      mat-button mat-dialog-close translate>{{data.btnText}}</button>
      <button style="    
      background: #0000ff;
      border: none;
      font-weight: bold;
      padding: 8px 29px;
      color: white !important;
      margin-top: 1rem;" 
      *ngIf="secondCallback"
      (click)="onSecondButtonClick()"
      mat-button translate>{{secondButtonText}}</button>
    </div>
</div>
