import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollSectionService {

  private sectionToScroll = new Subject<string>();

  sectionToScroll$ = this.sectionToScroll.asObservable();

  constructor(private router: Router) {}

  scrollTo(sectionId: string) {
    if (this.router.url !== '/') {
      // Si no estás en la ruta principal, navega a la página principal primero
      this.router.navigateByUrl('/').then(() => {
        this.sectionToScroll.next(sectionId);
      });
    } else {
      this.sectionToScroll.next(sectionId);
    }
  }
}

