import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCustomData } from 'src/app/models/dialog-custom-data';

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.css']
})

export class CustomDialogComponent {

  callback?: Function;
  secondCallback?: Function;
  secondButtonText?: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogCustomData) {
    this.callback = data.callback;
    this.secondCallback = data.secondCallback;
    this.secondButtonText = data.secondButtonText;
   }

   onButtonClick() {
    if (this.callback) {
      this.callback();
    }
  }

  onSecondButtonClick() {
    if (this.secondCallback) {
      this.secondCallback();
    }
  }
}
